import React from "react";
import "./scss/globals.scss";
import { Routes, Route, useNavigate } from "react-router-dom";

import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

import animateModule from "./modules/animateModule";
import * as backendModule from "./modules/backendModule";
import * as siteFunctionsActions from "./actions/siteFunctionsActions";
import * as userDataActions from "./actions/userDataActions";
import * as timestmapActions from "./actions/timestampActions";
import * as typesActions from "./actions/typesActions";
import * as trackingProfilesActions from "./actions/trackingProfilesActions";
import { getParamsFromURLObject } from "./modules/urlModule";
import useDefer from "./modules/hooks/useDefer";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Spinner from "./components/customComponents/Spinner";

import Login from "./routes/Login";
import ContentWrapper from "./components/ContentWrapper";

import Dashboard from "./routes/Dashboard";

import UserMediaBuyer from "./routes/MediaBuyer";
import UserOffers from "./routes/user/Offers";
import UserOfferCopies, { UserCopiesForOffer } from "./routes/user/OfferCopies";

import UserSites from "./routes/user/Sites";
import UserCampaigns from "./routes/user/Campaigns";
import UserAccountBalances from "./routes/user/AccountBalances";
import UserAccountManager from "./routes/user/AccountManager";
import UserAccountManager_userActivity from "./routes/user/AccountManager/userActivity";
import UserAccountManager_mediaBuyerCampaigns from "./routes/user/AccountManager/MediaBuyerCampaigns";
import UserIntegrations from "./routes/user/Integrations";
import UserAdditionalSpents from "./routes/user/AdditionalSpents";

import AdminUsers from "./routes/admin/Users";
import AdminDataStore from "./routes/admin/DataStore";
import AdminApiTokens from "./routes/admin/ApiTokens";
import AdminGlobalSharing from "./routes/admin/GlobalSharing";
import AdminDataChange from "./routes/admin/DataChange";
import AdminSiteEvents from "./routes/admin/SiteEvents";
import AdminReportedURLs from "./routes/admin/ReportedURLs";
import AdminBotDetection from "./routes/admin/BotDetection";

import AdminGlobalIntegrations from "./routes/admin/GlobalIntegrations";
import AdminGlobalIntegrations_JP from "./routes/admin/GlobalIntegrations/JP";
import AdminGlobalIntegrations_MW from "./routes/admin/GlobalIntegrations/MW";
import AdminGlobalIntegrations_MC from "./routes/admin/GlobalIntegrations/MC";

import TeamLeadUserGroups from "./routes/teamLead/UserGroups";
import LandingPage from "./routes/LandingPage";
import LandingHeader from "./components/LandingHeader";
import LandingFooter from "./components/LandingFooter";
import Register from "./routes/Register";
import UserCreatives from "./routes/user/Creatives";
import UserCreativeDesigner from "./routes/user/CreativeDesignerPanel";
import UserCampaignPerformanceTracking from "./routes/user/CampaignPerformanceTracking";

import PrivacyPolicy from "./routes/PrivacyPolicy";
import TermsOfService from "./routes/TermsOfService";
import DataDeletionPolicy from "./routes/DataDeletionPolicy";

import MarketingOffersReport from "./routes/user/reports/MarketingOffers";
import RealtimeReport from "./routes/user/reports/Realtime";
import BreakdownReport from "./routes/user/reports/Breakdown";
import FacebookExport from "./routes/user/reports/FacebookExport";
import DesignersReport from "./routes/user/reports/DesignersReport";
import SitePerformanceReport from "./routes/user/reports/SitePerformance";

import SocialMediaManager from "./routes/SocialMediaManager";
import SocialMediaManager_onlyOffers from "./routes/SocialMediaManager/viewOffers";
import SocialManager_Engage from "./routes/user/socialmanager/Engage";
import ShareCampaign from "./routes/ShareCampaign";
import LiveShare from "./routes/LiveShare";

import GuestManagers_midas from "./routes/guestManagers/Midas";

import DataImportsMidasAds from "./routes/dataImports/MidasAds";

let loginTimeout = null;
const App = () => {
  const userDataSelector = useSelector(state => state?.userData ?? {});
  const timestampSelector = useSelector(state => state?.timestamp ?? null);
  const siteTrackingProfilesTimestamp = useSelector(state => state?.siteFunctions?.siteTrackingProfilesTimestamp ?? Date.now());
  const siteFunctionsSelector = useSelector(state => state?.siteFunctions?.headerRefreshHandlers ?? []);
  const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
  const siteFunctionsStickyHeaderSelector = useSelector(state => state?.siteFunctions?.stickyHeader ?? true);
  const selectedTeamSelector = useSelector(state => state?.trackingProfiles?.selectedTeam ?? null);
  const selectedProfileSelector = useSelector(state => state?.trackingProfiles?.selectedProfile ?? null);
  const [sidebarOpened, setSidebarOpened] = React.useState(false);
  const [notifications, setNotifications] = React.useState();
  const [curURLParams, setCurURLParams] = React.useState({});
 
  const mainDispatch = useDispatch();
  const mainNavigate = useNavigate();

  const columnUpdateDefer = useDefer();

  const animateNavigate = to => {
    animateModule(mainNavigate, to, document.querySelector(".component__contentWrapper"));
  };

  const getTrackingProfiles = () => {
    if (!selectedTeamSelector) return;
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/common/getTrackingProfiles`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        mainDispatch(trackingProfilesActions.updateTrackingProfiles(res.data.data));
        
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/userSavedColumns/getColumns`,
          data: {
              Integration: `initial_selected_profile`
          },
          ...backendModule.axiosConfig
        }).then(res2 => {
          if (res2.data.status === "ok" && res2.data.data?.[0] && res2.data.data?.[1]) {
            if (res2.data.data[0] === selectedTeamSelector) return mainDispatch(trackingProfilesActions.selectTrackingProfile(res2.data.data[1] ?? null));
          };
          return mainDispatch(trackingProfilesActions.selectTrackingProfile(res.data.data[0]?.ID ?? null));
        }).catch(() => {
          return mainDispatch(trackingProfilesActions.selectTrackingProfile(res.data.data[0]?.ID ?? null));
        });

      } else {
        mainDispatch(trackingProfilesActions.selectTrackingProfile(null));
      };
    }).catch(() => {
      mainDispatch(trackingProfilesActions.selectTrackingProfile(null));
    });
  };

  const getTrackingTeams = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/common/getTrackingTeams`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        mainDispatch(trackingProfilesActions.updateTrackingTeams(res.data.data));
        mainDispatch(trackingProfilesActions.selectTrackingTeam(res.data.data[0]?.ID ?? null));

        axios({
          method: "POST",
          url: `${backendModule.backendURL}/userSavedColumns/getColumns`,
          data: {
              Integration: `initial_selected_team`
          },
          ...backendModule.axiosConfig
        }).then(res2 => {
          if (res2.data.status === "ok" && res2.data.data?.[0]) return mainDispatch(trackingProfilesActions.selectTrackingTeam(res2.data.data[0] ?? null));
          return mainDispatch(trackingProfilesActions.selectTrackingTeam(res.data.data[0]?.ID ?? null));
        }).catch(() => {
          return mainDispatch(trackingProfilesActions.selectTrackingTeam(res.data.data[0]?.ID ?? null));
        });

      } else {
        mainDispatch(trackingProfilesActions.selectTrackingTeam(null));
      };
    }).catch(() => {
      mainDispatch(trackingProfilesActions.selectTrackingTeam(null));
    });
  };

  const getTypes = () => {
    if (Object.keys(backendModule.getStore().getState().types).length > 0) return;

    axios({
      method: "POST",
      url: `${backendModule.backendURL}/common/getTypes`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        mainDispatch(typesActions.setTypes(res.data.data));
      };
    }).catch(() => null);
  };

  const getNotifications = () => {
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/notifications/getAllNotifications`,
      data: {
        limit: 200
      },
      ...backendModule.axiosConfig
    }).then(res => {
      setNotifications(res.data)
    }).catch(() => {
      return null;
    })
  }

  const getTheme = async () => {
    let curTheme = await axios({
      method: "POST",
      url: `${backendModule.backendURL}/users/vars/getVar`,
      data: {
        Name: "user_theme"
      },
      ...backendModule.axiosConfig
    }).then(res => res.data).catch(() => backendModule.genericError);

    if (curTheme.status === "ok") {
      mainDispatch(siteFunctionsActions.changeSiteTheme(curTheme.data));
    };
  };

  const checkLogin = () => {
    getNotifications();
    axios({
      method: "POST",
      url: `${backendModule.backendURL}/auth/checkLogin`,
      ...backendModule.axiosConfig
    }).then(res => {
      if (res.data.status === "ok") {
        getTypes();

        if (!userDataSelector?.isLoggedIn) {
          mainDispatch(userDataActions.setUserData(true, res.data.data));
          if (window.location.toString().endsWith("/login")) animateNavigate("/");
        };

        loginTimeout = setTimeout(checkLogin, 60000);
      } else {
        if (
          !window.location.toString().endsWith("/#/privacy") &&
          !window.location.hash.startsWith("#/share-campaign/") &&
          !window.location.hash.startsWith("#/live-share")
        ) animateNavigate("/");
        mainDispatch(userDataActions.setUserData(false, null));
      };
    }).catch(() => {
      mainDispatch(userDataActions.setUserData(null, null));
      setTimeout(() => mainDispatch(timestmapActions.updateTimestamp()), 2000);

      loginTimeout = setTimeout(checkLogin, 2000);
    });
  };

  React.useEffect(() => {
    checkLogin();

    return () => clearTimeout(loginTimeout);
  }, [timestampSelector]);

  React.useEffect(() => {
    if (!userDataSelector?.isLoggedIn) return;

    getTrackingTeams();
    getTheme();
  }, [siteTrackingProfilesTimestamp, userDataSelector?.isLoggedIn]);

  React.useEffect(() => {
    if (!selectedTeamSelector) return;

    let handler = axios.interceptors.request.use(config => {
      if (!config.params) config.params = {};
      config.params["teamID"] = selectedTeamSelector;
      return config;
    });

    getTrackingProfiles();

    for (let item of siteFunctionsSelector) item();

    return () => axios.interceptors.request.eject(handler);
  }, [selectedTeamSelector]);

  React.useEffect(() => {
    columnUpdateDefer(() => {
      if (selectedTeamSelector) {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/userSavedColumns/updateColumns`,
          data: {
              Integration: `initial_selected_team`,
              Columns: [selectedTeamSelector]
          },
          ...backendModule.axiosConfig
        }).then(() => null).catch(() => null);
      };

      if (selectedProfileSelector && selectedTeamSelector) {
        axios({
          method: "POST",
          url: `${backendModule.backendURL}/userSavedColumns/updateColumns`,
          data: {
              Integration: `initial_selected_profile`,
              Columns: [selectedTeamSelector, selectedProfileSelector]
          },
          ...backendModule.axiosConfig
        }).then(() => null).catch(() => null);
      };
    }, 1000);
  }, [selectedTeamSelector, selectedProfileSelector]);

  React.useEffect(() => {
    let handler = (e) => {
      if (
        e?.oldURL.endsWith("/login") ||
        e?.newURL.endsWith("/login")
      ) setTimeout(() => mainDispatch(timestmapActions.updateTimestamp()), 0);
    };

    window.addEventListener("hashchange", handler);

    return () => window.removeEventListener("hashchange", handler);
  }, []);

  React.useEffect(() => {
    if (!userDataSelector?.isLoggedIn) {
      mainDispatch(trackingProfilesActions.resetTrackingData())
    };
  }, [timestampSelector, userDataSelector?.isLoggedIn]);

  React.useEffect(() => {
    if (themeSelector) {
      switch (themeSelector) {
        case "light":
          document.body.classList.remove("dark");
          document.body.classList.add("light");
          break;
        case "dark":
          document.body.classList.remove("light");
          document.body.classList.add("dark");
          break;
        default: break;
      };
    };
  }, [themeSelector]);

  React.useEffect(() => {
    setCurURLParams(getParamsFromURLObject(String(window.location)));
  }, []);

  const toggleSidebar = () => {
    setSidebarOpened(d => !d);
  }

  if (window.location.hash.startsWith("#/share-campaign/")) return <ShareCampaign />;
  if (window.location.hash.startsWith("#/live-share")) return <LiveShare />;

  return userDataSelector.isLoggedIn === null ? <div className="root__init">
    <Spinner color="#FCA311" />
  </div> : (userDataSelector.isLoggedIn ? <React.Fragment key="logged-in-fragment">
    <Sidebar
      sidebarOpened={sidebarOpened}
      toggleSidebar={() => toggleSidebar} sidebar={sidebarOpened} setSidebar={setSidebarOpened}
      notifications={notifications}
    />

    <ContentWrapper key="wrapper-loggedIn" style={{
      display: "grid",
      gridTemplateRows: "1fr",
      gridTemplateColumns: "1fr",
      rowGap: "20px",
      paddingLeft: '84px'
    }}>
      <div className={`root__content ${siteFunctionsStickyHeaderSelector ? "" : "root__content--noSticky"}`}>
        <Routes>
          {(()=>{
            if (curURLParams?.["_mediabuyer-viewbyid"]) return <>
              <Route path="/" element={<UserCampaigns />} />
              <Route path="/sites" element={<UserSites />} />
            </>

            return <>
              {userDataSelector?.userData?.UserInfo?.Flags?.isTeamLeader && <>
                <Route path="/team-usergroups" element={<TeamLeadUserGroups />} />
                <Route path="/team-users" element={<AdminUsers teamLead={true} key="admin-users-lead" />} />
              </>}

              {userDataSelector?.userData?.UserInfo?.Flags?.isAdmin && <>
                <Route path="/admin-users" element={<AdminUsers ey="admin-users-admin" />} />
                <Route path="/admin-datastore" element={<AdminDataStore />} />
                <Route path="/admin-apiTokens" element={<AdminApiTokens />} />
                <Route path="/admin-globalSharing" element={<AdminGlobalSharing />} />
                <Route path="/admin-reportedURLs" element={<AdminReportedURLs />} />
                <Route path="/admin-dataChange" element={<AdminDataChange />} />
                <Route path="/admin-trackEvents" element={<AdminSiteEvents />} />
                <Route path="/admin-botDetection" element={<AdminBotDetection />} />
                <Route path="/admin-globalIntegrations" element={<AdminGlobalIntegrations />} />
                <Route path="/admin-globalIntegrations/jp" element={<AdminGlobalIntegrations_JP />} />
                <Route path="/admin-globalIntegrations/mw" element={<AdminGlobalIntegrations_MW />} />
                <Route path="/admin-globalIntegrations/mc" element={<AdminGlobalIntegrations_MC />} />
              </>}

              {(userDataSelector?.userData?.UserInfo?.Flags?.isSocialMediaManager || userDataSelector?.userData?.UserInfo?.Flags?.isMediaBuyer) && <>
                {userDataSelector?.userData?.UserInfo?.Flags?.isMediaBuyer ? <Route path="/" element={<UserMediaBuyer />} /> : (userDataSelector?.userData?.UserInfo?.Flags?.socialMediaManager_socialOnly ? <>
                  <Route path="/" element={<SocialMediaManager_onlyOffers />} />
                </> : <>
                  <Route path="/" element={<SocialMediaManager />} />
                </>)}
              </>}

              {userDataSelector?.userData?.UserInfo?.Flags?.isGuestManagerMD && <>
                <Route path="/" element={<GuestManagers_midas />} />
              </>}

              <Route path="/" element={<Dashboard />} />
              <Route path="/campaigns" element={<UserCampaigns />} />
              <Route path="/campaign-performance-tracking" element={<UserCampaignPerformanceTracking />} />

              {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.canViewCreatives) && <Route path="/creatives" element={<UserCreatives />} />}
              {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.creatives_isDesigner) && <Route path="/creative-designer" element={<UserCreativeDesigner />} />}
              {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.isAccountManager) && <Route path="/account-manager/accounts" element={<UserAccountManager />} />}
              {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.isAccountManager) && <Route path="/account-manager/mediaBuyerCampaigns" element={<UserAccountManager_mediaBuyerCampaigns />} />}
              {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.isAccountManager) && <Route path="/account-manager/mediaBuyerCampaignSummary" element={<UserAccountManager_userActivity />} />}
              {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.isAccountManager) && <Route path="/account-manager/datastore" element={<AdminDataStore />} />}
              {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.isAccountManager) && <Route path="/account-manager/reportedurls" element={<AdminReportedURLs />} />}

              {(userDataSelector?.userData?.UserInfo?.Flags?.canImportIntegrationData && <Route path="/data-imports/midas-ads" element={<DataImportsMidasAds />} />)}

              <Route path="/sites" element={<UserSites />} />
              <Route path="/offers" element={<UserOffers />} />
              <Route path="/additional-spents" element={<UserAdditionalSpents />} />
              {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.canViewOfferCopies || userDataSelector?.userData?.UserInfo?.Flags?.canManageOfferCopies) && <>
                <Route path="/offer-copies" element={<UserOfferCopies />} />
                <Route path="/offer-copies/:id" element={<UserCopiesForOffer />} />
              </>}

              {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || userDataSelector?.userData?.UserInfo?.Flags?.canAddBalances) && <Route path="/accountBalances" element={<UserAccountBalances />} />}

              {userDataSelector?.userData?.Integrations?.jp && <>
                <Route path="/socialmanager/engage" element={<SocialManager_Engage />} />
              </>}

              {(userDataSelector?.userData?.UserInfo?.Flags?.isAdmin || curURLParams["_admin-viewbyid"]) && <Route path="/integrations" element={<UserIntegrations />} />}

              {/* Reports */}
              <Route path="/reports/marketing" element={<MarketingOffersReport key={"marketing-report"} />} />
              <Route path="/reports/breakdown" element={<BreakdownReport key={"campaigns-report"} />} />
              <Route path="/reports/facebook" element={<FacebookExport key={"facebook-report"} />} />
              <Route path="/reports/designers" element={<DesignersReport key={"designers-report"} />} />
              <Route path="/reports/sites" element={<SitePerformanceReport key={"sites-report"} />} />
              <Route path="/reports/realtime" element={<RealtimeReport key={"realtime-report"} />} />

            </>;
          })()}
        </Routes>
      </div>
    </ContentWrapper>
  </React.Fragment> : <>
    <ContentWrapper style={{ gridRow: "1 / span 2", gridColumn: "1 / span 2" }}>
      <LandingHeader />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/data-deletion" element={<DataDeletionPolicy />} />

        <Route path="/" element={<LandingPage />} />
      </Routes>
      <LandingFooter />
    </ContentWrapper>
  </>)
};

export default App;
