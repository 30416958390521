import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import axios from "axios";
import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";

import Spinner from "../../components/customComponents/Spinner";

const Register = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [registerConplete, setRegisterComplete] = React.useState(false);
    const [infoP, setInfoP] = React.useState({
        hadError: false,
        inputs: [],
        error: "-"
    });

    const firstNameRef = React.useRef();
    const lastNameRef = React.useRef();
    const storeURLRef = React.useRef();

    const usernameRef = React.useRef();
    const passwordRef = React.useRef();
    const emailRef = React.useRef();
    const confirmPasswordRef = React.useRef();
    const componentRegisterRef = React.useRef();

    const emailVertification = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const validateEmail = (input) => {
        if (input.match(emailVertification)) {
            return true;
        } else {
            return false;
        }
    }

    const curNavigate = useNavigate();

    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const registerUser = () => {
        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        let data = {
            FirstName: firstNameRef.current.value,
            LastName: lastNameRef.current.value,
            Email: emailRef.current.value,
            Username: usernameRef.current.value,
            Password: passwordRef.current.value,
            ConfirmPassword: confirmPasswordRef.current.value,
            StoreURL: storeURLRef.current.value
        };

            //TODO handle password lengths < 8 > 64
        if (!data.FirstName) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["first-name"], error: "First name is required"}});
        };
        if (!data.LastName) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["last-name"], error: "Last name is required"}});
        };
        if (!data.StoreURL) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["store-url"], error: "Store URL is required"}});
        };
        if (!data.Email || !validateEmail(data.Email)) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["email"], error: "Email can't be empty, or it must be entered properly"}});
        };
        if (!data.Username) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["username"], error: "Username can't be empty."}});
        };
        if (!data.Password) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["password"], error: "Password can't be empty."}});
        };
        if (data.Password.length < 8 || data.Password.length > 64) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["password"], error: "Password must be between 8 and 64 characters."}});
        };
        if (!data.ConfirmPassword) {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["confirmPassword"], error: "Confirm Password field cannot be empty"}});
        };
        if(passwordRef.current.value !== confirmPasswordRef.current.value){
            return setInfoP(ip => {return {...ip, hadError: true, inputs: ["confirmPassword"], error: "Password don't match"}});
        }

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/registerClient`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                setRegisterComplete(true);
            } else {
                setInfoP(ip => {return {...ip, hadError: true, inputs: [], error: "There was an error while registering. Please check the information you provided and try again!"}});
            };
        }).catch(() => {
            setInfoP(ip => {return {...ip, hadError: true, inputs: [], error: "Server timed out!"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        componentRegisterRef.current.scrollIntoView({
            behavior:'instant'
        })
    
    }, []);

    return <div className="component__register" ref={componentRegisterRef}>
        <div className="component__register__left" style={{
            backgroundImage: 'url("./images/login_img.png")'
        }}></div>

        <div className="component__register__right">
            <div className="component__register__right__logo" style={{backgroundImage: 'url("./images/logo.svg")'}}></div>
            <p className="component__register__right__text">Welcome to Scale Track</p>
            <p className="component__register__right__heading">Sign up</p>

            {registerConplete ? <>
                <p>Thank You for the registration!</p>
                <p>Your information is forwarded to our customer support, and we will contact you shortly.</p>
                <p>You can also contact us on info@scale-track.com</p>
            </> : <>
                <div className={`component__register__right__input ${infoP.inputs.includes("first-name") ? "component__register__right__input--error" : ""}`}>
                    <span>First name</span>
                    <input ref={firstNameRef} type="text" placeholder="Enter your first name" />
                </div>
                <div className={`component__register__right__input ${infoP.inputs.includes("last-name") ? "component__register__right__input--error" : ""}`}>
                    <span>Last name</span>
                    <input ref={lastNameRef} type="text" placeholder="Enter your last name" />
                </div>

                <div className={`component__register__right__input ${infoP.inputs.includes("email") ? "component__register__right__input--error" : ""}`}>
                    <span>Email</span>
                    <input ref={emailRef} type="text" placeholder="Enter your email" />
                </div>
                <div className={`component__register__right__input ${infoP.inputs.includes("username") ? "component__register__right__input--error" : ""}`}>
                    <span>User name</span>
                    <input ref={usernameRef} type="text" placeholder="Enter your user name" />
                </div>
                <div className={`component__register__right__input ${infoP.inputs.includes("password") ? "component__register__right__input--error" : ""}`}>
                    <span>Password</span>
                    <input ref={passwordRef} type="password" placeholder="Enter your Password" />
                </div>
                <div className={`component__register__right__input ${infoP.inputs.includes("confirmPassword") ? "component__register__right__input--error" : ""}`}>
                    <span>Confirm Password</span>
                    <input ref={confirmPasswordRef} type="password" placeholder="Confirm your Password" />
                </div>

                <div className={`component__register__right__input ${infoP.inputs.includes("store-url") ? "component__register__right__input--error" : ""}`}>
                    <span>Store URL</span>
                    <input ref={storeURLRef} type="text" placeholder="Enter your Store URL" />
                </div>

                <div className="component__register__right__button"
                    style={{
                        pointerEvents: spinner ? "none" : "all"
                    }}
                    onClick={registerUser}
                >
                    {spinner ? <Spinner style={{width: "36px", height: "36px"}} color="white" /> : "Register"}
                </div>
                <p className="component__register__right__signin">Already have an Account ? <span onClick={() => animateNavigate("/login")}>Sign in</span></p>

                <p className="component__register__right__infoP" style={{
                    opacity: infoP.hadError ? 1 : 0
                }}>{infoP.error}</p>
            </>}
        </div>
    </div>
};

export default Register;