import React from "react";
import "./index.scss";

import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../../../modules/backendModule";

import StyledInput from "../../../../../components/styledComponents/Input";
import Spinner from "../../../../../components/customComponents/Spinner";

const AddIntegration_Trafficstars = (props) => {
    const [curState, setCurState] = React.useState({state: 1, data: null});

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();
    const facebookWindowRef = React.useRef();

    const onClose = () => {
        try {
            facebookWindowRef?.current?.close();
        } catch {};

        if (props.onChange) props.onChange();
        if (!mainRef?.current) return props.onClose();

        mainRef.current.animate([
            {right: getComputedStyle(mainRef.current).right},
            {right: "-100%"}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
        return props.onClose();
    };

    React.useEffect(() => {
        if (!mainRef?.current) return;

        mainRef.current.animate([
            {right: getComputedStyle(mainRef.current).right},
            {right: 0}
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [mainRef.current]);

    

    if (props.regenToken) return <div className="route__user__integrations__addFacebook" onClick={onClose}>
        <div className="route__user__integrations__addFacebook__wrap" ref={mainRef} onClick={e => e.stopPropagation()}>
            <div className="route__user__integrations__addFacebook__wrap__head">
                <p className="route__user__integrations__addFacebook__wrap__head__text">Traffic Stars</p>
                <div className="route__user__integrations__addFacebook__wrap__head__close" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={onClose}></div>
            </div>

            <div className="route__user__integrations__addFacebook__wrap__data">
                {curState ? <>
                    <div className="route__user__integrations__addFacebook__wrap__data__steps">
                        <div className={`route__user__integrations__addFacebook__wrap__data__steps__step ${curState.state === 1 ? "route__user__integrations__addFacebook__wrap__data__steps__step--active" : ""} ${curState.state > 1 ? "route__user__integrations__addFacebook__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addFacebook__wrap__data__steps__step__num">1</p>
                            <p className="route__user__integrations__addFacebook__wrap__data__steps__step__text">Connect</p>
                        </div>

                        <div className={`route__user__integrations__addFacebook__wrap__data__steps__step ${curState.state === 2 ? "route__user__integrations__addFacebook__wrap__data__steps__step--active" : ""} ${curState.state > 2 ? "route__user__integrations__addFacebook__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addFacebook__wrap__data__steps__step__num">2</p>
                            <p className="route__user__integrations__addFacebook__wrap__data__steps__step__text">Finish</p>
                        </div>
                    </div>
                    
                    {curState.state === 1 && <AddIntegration_Trafficstars_1 regenToken={props.regenToken} ID={props.ID} setCurState={setCurState} facebookWindowRef={facebookWindowRef}/>}
                    {curState.state === 2 && <AddIntegration_Trafficstars_2 onClose={onClose} setCurState={setCurState} facebookWindowRef={facebookWindowRef}/>}
                </> : <Spinner color={themeSelector === "dark" ? "white" : "dark"} align="center" />}
            </div>
        </div>
    </div>

    return <div className="route__user__integrations__addFacebook" onClick={onClose}>
        <div className="route__user__integrations__addFacebook__wrap" ref={mainRef} onClick={e => e.stopPropagation()}>
            <div className="route__user__integrations__addFacebook__wrap__head">
                <p className="route__user__integrations__addFacebook__wrap__head__text">Traffic Stars</p>
                <div className="route__user__integrations__addFacebook__wrap__head__close" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={onClose}></div>
            </div>

            <div className="route__user__integrations__addFacebook__wrap__data">
                {curState ? <>
                    <div className="route__user__integrations__addFacebook__wrap__data__steps">
                        <div className={`route__user__integrations__addFacebook__wrap__data__steps__step ${curState.state === 1 ? "route__user__integrations__addFacebook__wrap__data__steps__step--active" : ""} ${curState.state > 1 ? "route__user__integrations__addFacebook__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addFacebook__wrap__data__steps__step__num">1</p>
                            <p className="route__user__integrations__addFacebook__wrap__data__steps__step__text">Connect</p>
                        </div>

                        <div className={`route__user__integrations__addFacebook__wrap__data__steps__step ${curState.state === 2 ? "route__user__integrations__addFacebook__wrap__data__steps__step--active" : ""} ${curState.state > 2 ? "route__user__integrations__addFacebook__wrap__data__steps__step--completed" : ""}`.trim()}>
                            <p className="route__user__integrations__addFacebook__wrap__data__steps__step__num">2</p>
                            <p className="route__user__integrations__addFacebook__wrap__data__steps__step__text">Finish</p>
                        </div>
                    </div>
                    
                    {curState.state === 1 && <AddIntegration_Trafficstars_1 setCurState={setCurState} facebookWindowRef={facebookWindowRef}/>}
                    {curState.state === 2 && <AddIntegration_Trafficstars_2 onClose={onClose} setCurState={setCurState} facebookWindowRef={facebookWindowRef}/>}
                </> : <Spinner color={themeSelector === "dark" ? "white" : "dark"} align="center" />}
            </div>
        </div>
    </div>
};

const AddIntegration_Trafficstars_1 = props => {
    const [spinner, setSpinner] = React.useState(false);
    const [checkData, setCheckData] = React.useState(false);
    const [infoP, setInfoP] = React.useState("");

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();
    const tokenRef = React.useRef();
    const postbackRef = React.useRef();

    const createIntegration = () => {
        if (spinner) return;
        setInfoP("");

        let data = {
            Integration: 7,
            Key: tokenRef.current.value,
            AdditionalData: {
                PostbackURL: postbackRef.current.value
            }
        };

        if (!data.Key) return setInfoP("API token missing");
        if (!data.AdditionalData.PostbackURL) return setInfoP("Postback URL missing");

        try {
            new URL(data.AdditionalData.PostbackURL);
        } catch {
            return setInfoP("Postback URL invalid");
        };
        if (!data.AdditionalData.PostbackURL.includes("{click_id}")) return setInfoP("Postback URL is missing the {click_id} parameter!");
        if (props.regenToken) data["ID"] = props.ID

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/${props.regenToken ? "updateIntegration" : "addIntegration"}`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.setCurState({state: 2, data: null});
            } else {
                if (res.data.data === "ALREADY_EXISTS") {
                    return setInfoP("An integration of this type already exists.");
                } else if (res.data.data === "TEST_FAILED") {
                    return setInfoP("Token test failed, Your API token is invalid!");
                } else {
                    return setInfoP(`An error occured while ${props.regenToken ? "updating" : "creating"} an integration`);
                };
            };
        }).catch(() => {
            return setInfoP(`Server timed out while ${props.regenToken ? "updating" : "creating"} an integration!`);
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: 0}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"});
    }, [mainRef.current]);

    React.useEffect(() => {
        if (props.regenToken) return setCheckData(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/integrations/checkIntegration`,
            data: {
                Integration: 7
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                props.setCurState({state: 2, data: null});
            } else {
                return setCheckData(true);
            };
        }).catch(() => {
            return setCheckData(true);
        });
    }, []);

    if (!checkData) return <div className="route__user__integrations__addFacebook__wrap__data__step1" ref={mainRef}>
        <p>Checking for existing integrations...</p>

        <Spinner color={themeSelector === "dark" ? "white" : "black"} align="center" />
    </div>

    return <div className="route__user__integrations__addScalelead__wrap__data__step1" ref={mainRef}>
        <p className="route__user__integrations__addScalelead__wrap__data__step1__text">
            {props.regenToken ? <>
                <p>Your integration details have expired</p>
                <p>Please provide new details!</p>
            </> : <>
                To create a Traffic stars integration, you will need to do 2 things:
                <br />
                <br />
                <span>1) Add your traffic stars token here</span>
                <br />
                <span>2) Add your postback url (created in 'Tracking tools') so network can receive conversions</span>
            </>}
        </p>
        <p>Enter your API token:</p>
        <StyledInput ref={tokenRef} placeholder="API Token" />

        <p>Enter your postback URL here:</p>
        <StyledInput ref={postbackRef} placeholder="Postback URL" />

        <div className="route__user__integrations__addScalelead__wrap__data__step1__btn" onClick={createIntegration}>
            {spinner ? <Spinner color={themeSelector === "dark" ? "white" : "black"} style={{width: "24px", height: "24px"}} /> : "Continue"}
        </div>

        {infoP && <p className="route__user__integrations__addScalelead__wrap__infoP">{infoP}</p>}
    </div>
};

const AddIntegration_Trafficstars_2 = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const mainRef = React.useRef();

    React.useEffect(() => {
        if (!mainRef.current) return;

        mainRef.current.animate([
            {left: getComputedStyle(mainRef.current).left},
            {left: 0}
        ], {duration: 300, iterations: 1, easing: "ease", fill: "both"});
    }, [mainRef.current]);
    
    return <div className="route__user__integrations__addScalelead__wrap__data__step3" ref={mainRef}>
        <p>Your account is all set up!</p>

        <button className="route__user__integrations__addFacebook__wrap__data__step3__close" onClick={props.onClose}>Finish</button>
    </div>
};

export default AddIntegration_Trafficstars;