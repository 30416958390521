import React from "react";
import "./index.scss";

import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import * as backendModule from "../../modules/backendModule";
import animateModule from "../../modules/animateModule";
import { getParamsFromURLObject } from "../../modules/urlModule";

import Spinner from "../../components/customComponents/Spinner";

let numberFormat = new Intl.NumberFormat("en-us", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});
let staticFormat = new Intl.NumberFormat("en-us", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
});
const Dashboard = () => {
    const [data, setData] = React.useState(); // {campaigns: [], stats: {"today": {}, "yesterday": {}...}}

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const currencySignSelector = useSelector(state => state?.types?.currencySign ?? "?");

    const curNavigate = useNavigate();
    const animateNavigate = to => animateModule(curNavigate, to, document.querySelector(".component__contentWrapper"));

    const getData = () => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/reports/getMainReport`,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") return setData(res.data);
            if (res.data.data === "NOT_FOUND") {
                return setData({...res.data, data: "Reports not yet generated!"});
            };
            setData(res.data);
        }).catch(() => setData(backendModule.genericError));
    };

    React.useEffect(() => {
        // let curParams = getParamsFromURLObject(String(window.location));
        // if (!curParams.test) {
        //     animateNavigate("/campaigns");
        //     return;
        // };
        getData();
    }, []);

    return <div className="route__dashboard">
        <div className="route__dashboard__top">
            <p className="route__dashboard__top__head">Dashboard {data?.status === "ok" && <span>Last update: {moment(data.data.updatedAt).toDate().toLocaleString()}</span>}</p>
        </div>

        <div className="route__dashboard__grid">
            <div className="route__dashboard__grid__box">
                <div className="route__dashboard__grid__box__head route__dashboard__grid__box__head--blue">Spent</div>
                <div className="route__dashboard__grid__box__content2">
                    {data ? (data.status === "ok" ? <>
                        <div>
                            <p>Today</p>
                            <span>{numberFormat.format(Number(data.data.general.today.Spent).toFixed(2))} {currencySignSelector}</span>
                        </div>
                        <div>
                            <p>Yesterday</p>
                            <span>{numberFormat.format(Number(data.data.general.yesterday.Spent).toFixed(2))} {currencySignSelector}</span>
                        </div>
                        <div>
                            <p>This month</p>
                            <span>{numberFormat.format(Number(data.data.general.thisMonth.Spent).toFixed(2))} {currencySignSelector}</span>
                        </div>
                        <div>
                            <p>Last month</p>
                            <span>{numberFormat.format(Number(data.data.general.lastMonth.Spent).toFixed(2))} {currencySignSelector}</span>
                        </div>
                    </> : <p className="route__dashboard__grid__box__error">{data.data}</p>) : <Spinner style={{width: "24px", height: "24px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                </div>
            </div>
            <div className="route__dashboard__grid__box">
                <div className="route__dashboard__grid__box__head route__dashboard__grid__box__head--green">Revenue</div>
                <div className="route__dashboard__grid__box__content2">
                    {data ? (data.status === "ok" ? <>
                        <div>
                            <p>Today</p>
                            <span>{numberFormat.format(Number(data.data.general.today.Revenue).toFixed(2))} {currencySignSelector}</span>
                        </div>
                        <div>
                            <p>Yesterday</p>
                            <span>{numberFormat.format(Number(data.data.general.yesterday.Revenue).toFixed(2))} {currencySignSelector}</span>
                        </div>
                        <div>
                            <p>This month</p>
                            <span>{numberFormat.format(Number(data.data.general.thisMonth.Revenue).toFixed(2))} {currencySignSelector}</span>
                        </div>
                        <div>
                            <p>Last month</p>
                            <span>{numberFormat.format(Number(data.data.general.lastMonth.Revenue).toFixed(2))} {currencySignSelector}</span>
                        </div>
                    </> : <p className="route__dashboard__grid__box__error">{data.data}</p>) : <Spinner style={{width: "24px", height: "24px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                </div>
            </div>
            <div className="route__dashboard__grid__box">
                <div className="route__dashboard__grid__box__head route__dashboard__grid__box__head--yellow">ROAS</div>
                <div className="route__dashboard__grid__box__content2">
                    {data ? (data.status === "ok" ? <>
                        <div>
                            <p>Today</p>
                            <span>{Number(data.data.general.today.ROAS).toFixed(2)} x</span>
                        </div>
                        <div>
                            <p>Yesterday</p>
                            <span>{Number(data.data.general.yesterday.ROAS).toFixed(2)} x</span>
                        </div>
                        <div>
                            <p>This month</p>
                            <span>{Number(data.data.general.thisMonth.ROAS).toFixed(2)} x</span>
                        </div>
                        <div>
                            <p>Last month</p>
                            <span>{Number(data.data.general.lastMonth.ROAS).toFixed(2)} x</span>
                        </div>
                    </> : <p className="route__dashboard__grid__box__error">{data.data}</p>) : <Spinner style={{width: "24px", height: "24px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                </div>
            </div>


            <div className="route__dashboard__grid__box">
                <div className="route__dashboard__grid__box__head route__dashboard__grid__box__head--cyan">Visits</div>
                <div className="route__dashboard__grid__box__content5">
                    {data ? (data.status === "ok" ? <>
                        <div>
                            <p>Today</p>
                            <p>Conversions</p>
                            <p>CR</p>
                            <p>Approved</p>
                            <p>AR</p>

                            <span>{staticFormat.format(Number(data.data.general.today.Visits).toFixed(0))}</span>
                            <span>{staticFormat.format(Number(data.data.general.today.Conversions).toFixed(0))}</span>
                            <span>{numberFormat.format(Number(data.data.general.today.CR).toFixed(2))} %</span>
                            <span>{staticFormat.format(Number(data.data.general.today.Approved).toFixed(0))}</span>
                            <span>{numberFormat.format(Number(data.data.general.today.AR).toFixed(2))} %</span>
                        </div>
                        <div>
                            <p>Yesterday</p>
                            <p>Conversions</p>
                            <p>CR</p>
                            <p>Approved</p>
                            <p>AR</p>

                            <span>{staticFormat.format(Number(data.data.general.yesterday.Visits).toFixed(0))}</span>
                            <span>{staticFormat.format(Number(data.data.general.yesterday.Conversions).toFixed(0))}</span>
                            <span>{numberFormat.format(Number(data.data.general.yesterday.CR).toFixed(2))} %</span>
                            <span>{staticFormat.format(Number(data.data.general.yesterday.Approved).toFixed(0))}</span>
                            <span>{numberFormat.format(Number(data.data.general.yesterday.AR).toFixed(2))} %</span>
                        </div>
                        <div>
                            <p>This month</p>
                            <p>Conversions</p>
                            <p>CR</p>
                            <p>Approved</p>
                            <p>AR</p>

                            <span>{staticFormat.format(Number(data.data.general.thisMonth.Visits).toFixed(0))}</span>
                            <span>{staticFormat.format(Number(data.data.general.thisMonth.Conversions).toFixed(0))}</span>
                            <span>{numberFormat.format(Number(data.data.general.thisMonth.CR).toFixed(2))} %</span>
                            <span>{staticFormat.format(Number(data.data.general.thisMonth.Approved).toFixed(0))}</span>
                            <span>{numberFormat.format(Number(data.data.general.thisMonth.AR).toFixed(2))} %</span>
                        </div>
                        <div>
                            <p>Last month</p>
                            <p>Conversions</p>
                            <p>CR</p>
                            <p>Approved</p>
                            <p>AR</p>

                            <span>{staticFormat.format(Number(data.data.general.lastMonth.Visits).toFixed(0))}</span>
                            <span>{staticFormat.format(Number(data.data.general.lastMonth.Conversions).toFixed(0))}</span>
                            <span>{numberFormat.format(Number(data.data.general.lastMonth.CR).toFixed(2))} %</span>
                            <span>{staticFormat.format(Number(data.data.general.lastMonth.Approved).toFixed(0))}</span>
                            <span>{numberFormat.format(Number(data.data.general.lastMonth.AR).toFixed(2))} %</span>
                        </div>
                    </> : <p className="route__dashboard__grid__box__error">{data.data}</p>) : <Spinner style={{width: "24px", height: "24px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                </div>
            </div>
            <div className="route__dashboard__grid__box">
                <div className="route__dashboard__grid__box__head route__dashboard__grid__box__head--cyan">Best sites</div>
                <div className="route__dashboard__grid__box__content">
                    {data ? (data.status === "ok" ? <>
                        <div>
                            <p>Today</p>
                            <p>Revenue</p>
                            <p>Conversions</p>

                            {(()=>{
                                let out = [];
                                data.data.extended.today.sites.forEach(c => {
                                    out.push(<span>{c.Name}</span>);
                                    out.push(<span>{numberFormat.format(Number(c.Revenue).toFixed(2))} {currencySignSelector}</span>);
                                    out.push(<span>{c.Conversions}</span>);
                                });
                                while (out.length < 9) out.push(<span>-</span>);
                                return out;
                            })()}
                        </div>
                        <div>
                            <p>Yesterday</p>
                            <p>Revenue</p>
                            <p>Conversions</p>

                            {(()=>{
                                let out = [];
                                data.data.extended.yesterday.sites.forEach(c => {
                                    out.push(<span>{c.Name}</span>);
                                    out.push(<span>{numberFormat.format(Number(c.Revenue).toFixed(2))} {currencySignSelector}</span>);
                                    out.push(<span>{c.Conversions}</span>);
                                });
                                while (out.length < 9) out.push(<span>-</span>);
                                return out;
                            })()}
                        </div>
                    </> : <p className="route__dashboard__grid__box__error">{data.data}</p>) : <Spinner style={{width: "24px", height: "24px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                </div>
            </div>
            <div className="route__dashboard__grid__box">
                <div className="route__dashboard__grid__box__head route__dashboard__grid__box__head--cyan">Best sources</div>
                <div className="route__dashboard__grid__box__content">
                    {data ? (data.status === "ok" ? <>
                        <div>
                            <p>Today</p>
                            <p>Revenue</p>
                            <p>Conversions</p>

                            {(()=>{
                                let out = [];
                                data.data.extended.today.sources.forEach(c => {
                                    out.push(<span>{c.Name}</span>);
                                    out.push(<span>{numberFormat.format(Number(c.Revenue).toFixed(2))} {currencySignSelector}</span>);
                                    out.push(<span>{c.Conversions}</span>);
                                });
                                while (out.length < 9) out.push(<span>-</span>);
                                return out;
                            })()}
                        </div>
                        <div>
                            <p>Yesterday</p>
                            <p>Revenue</p>
                            <p>Conversions</p>

                            {(()=>{
                                let out = [];
                                data.data.extended.yesterday.sources.forEach(c => {
                                    out.push(<span>{c.Name}</span>);
                                    out.push(<span>{numberFormat.format(Number(c.Revenue).toFixed(2))} {currencySignSelector}</span>);
                                    out.push(<span>{c.Conversions}</span>);
                                });
                                while (out.length < 9) out.push(<span>-</span>);
                                return out;
                            })()}
                        </div>
                    </> : <p className="route__dashboard__grid__box__error">{data.data}</p>) : <Spinner style={{width: "24px", height: "24px"}} color={themeSelector === "dark" ? "white" : "black"} />}
                </div>
            </div>
        </div>
    </div>
};

export default Dashboard;