import React from "react";
import "./index.scss";

import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import * as backendModule from "../../../modules/backendModule";
import { animateBox } from "../../../modules/componentAnimation";
import { parseCSV } from "../../../modules/csvParserModule";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import StyledButton from "../../../components/styledComponents/Button";
import StyledInput from "../../../components/styledComponents/Input";

import YesNoModal from "../../../components/modals/YesNoModal";

const DataImportsMidasAds = () => {
    const [queues, setQueues] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [spinner, setSpinner] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const dateRef = React.useRef();

    const processQueue = async () => {
        if (spinner) return;
        let curDate = dateRef.current.value ? moment(dateRef.current.value) : null;
        let curFiles = files;

        if (!curDate || !curDate.isValid()) return animateBox(<YesNoModal
            heading="Date missing"
            text="Date can't be empty"
            buttonLeftHidden={true}
            buttonRightText="Ok"
            isRightButtonNormal={true}
        />);
        if (moment(curDate).endOf("day").isAfter(moment().endOf("day"))) return animateBox(<YesNoModal
            heading="Date invalid"
            text="Date can't be in the future"
            buttonLeftHidden={true}
            buttonRightText="Ok"
            isRightButtonNormal={true}
        />);
        if (curFiles.length === 0) return animateBox(<YesNoModal
            heading="Files missing"
            text="Add at least 1 file"
            buttonLeftHidden={true}
            buttonRightText="Ok"
            isRightButtonNormal={true}
        />);

        setSpinner(true);
        let finalData = [];
        for (let file of curFiles) {
            let curText = await file.text().catch(() => "");
            if (!curText) continue;
            let curCSV = await parseCSV(curText).catch(() => null);

            if (!Array.isArray(curCSV)) {
                setSpinner(false);
                return animateBox(<YesNoModal
                    heading="CSV invalid"
                    text={`File ${file.name} is invalid`}
                    buttonLeftHidden={true}
                    buttonRightText="Ok"
                    isRightButtonNormal={true}
                />);
            };
            if (curCSV.length <= 1) continue;

            let headers = curCSV.shift();
            let data = curCSV;

            let curColumns = [
                "Campaign name",
                "Ad name",
                "Clicks",
                "Impressions",
                "Cost",
                "CTR",
                "imageUrl"
            ];
            let headerIndexes = {};

            let curRewrites = {
                "Campaign name": "CampaignName",
                "Ad name": "AdName",
                "imageUrl": "Image"
            }

            for (let i=0; i<=headers.length-1; i++) {
                if (curColumns.includes(headers[i])) headerIndexes[headers[i]] = i;
            };

            if (Object.keys(headerIndexes).length !== curColumns.length) {
                setSpinner(false);
                let missingHeaders = [];
                let keys = Object.keys(headerIndexes);
                for (let col of curColumns) {
                    if (!keys.includes(col)) missingHeaders.push(col);
                };
                return animateBox(<YesNoModal
                    heading="CSV invalid"
                    text={`File ${file.name} is missing required headers. Check if the following headers exist: ${missingHeaders.join(",")}`}
                    buttonLeftHidden={true}
                    buttonRightText="Ok"
                    isRightButtonNormal={true}
                />);
            };

            let keys = Object.keys(headerIndexes);
            for (let item of data) {
                let tmp = {};
                for (let key of keys) {
                    let finalColumn = key;
                    if (curRewrites[finalColumn]) finalColumn = curRewrites[finalColumn];
                    tmp[finalColumn] = item[headerIndexes[key]];
                }
                finalData.push(tmp);
            };
        };
        let dmy = moment(curDate).format("DD.MM.YYYY");
        if (queues.find(q => q.Date === dmy)) {
            setQueues(q => {
                let tmpq = [...q];
                for (let item of tmpq) {
                    if (item.Date === dmy) {
                        item.Data.push(...finalData);
                        break;
                    }; 
                };
                return tmpq;
            });
        } else {
            setQueues(q => [...q, {Date: dmy, Data: finalData}]);
        };
        setSpinner(false);
        dateRef.current.value = "";
        setFiles([]);
    };

    const finalizeQueue = () => {
        if (queues.length === 0) return;

        animateBox(<YesNoModal
            heading="Are you sure?"
            text={`You are about to finalize ${queues.length} dates. Make sure that you have checked all the files and dates before you proceed!`}
            buttonLeftText="Cancel"
            isRightButtonNormal={true}
            buttonRightText="Process"
            buttonRightCallback={async args => {
                args.spinner(true);
                args.disabledAll(true);
                args.errorMessage("");

                let count = 0;
                let max = queues.length;
                args.setProgress({value: 0, max: queues.length});
                for (let q of queues) {
                    let tmpOut = await axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/dataImports/midasAds`,
                        data: {
                            Date: moment(q.Date, "DD.MM.YYYY").startOf("day").add(12, "hours").toDate().getTime(),
                            Data: q.Data
                        },
                        ...backendModule.axiosConfig
                    }).then(res => res.data).catch(() => backendModule.genericError);

                    if (tmpOut.status === "error") {
                        args.setProgress();
                        args.spinner(false);
                        args.disabledAll(false);
                        args.errorMessage(`Error while processing ads for date ${q.Date}`);
                        return;
                    } else {
                        count += 1;
                        args.setProgress({value: count, max: max});
                        setQueues(qq => qq.filter(qqq => qqq.Date !== q.Date));
                    };
                };

                args.spinner(false);
                args.disabledAll(false);
                args.setProgress();
                args.close();

                animateBox(<YesNoModal
                    heading="Done"
                    text="Finished processing queues!"
                    buttonLeftHidden={true}
                    isRightButtonNormal={true}
                    buttonRightText="Ok"
                />);
            }}
        />)
    };

    return <div className="route__dataImports__midasAds">
        <div className="route__dataImports__midasAds__import">
            <h3>Import midas ads CSV files</h3>
            <p>
                <span>Date</span>
                <StyledInput ref={dateRef} type="date" />
            </p>
            <p>
                <span>Files</span>
                <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                    {files.map((f, fIdx) => {
                        return <div className="route__dataImports__midasAds__import__pill" onClick={() => {
                            setFiles(ff => ff.filter((_, ffIdx) => fIdx !== ffIdx));
                        }}>{f.name}</div>
                    })}

                    <StyledButton isSecondary={true} onClick={e => {
                        e.target.parentNode.querySelector(`input[type="file"]`).click();
                    }}>Add</StyledButton>
                    <input type="file" accept="text/csv" multiple={true} style={{display: "none"}} onChange={e => {
                        if (e.target.files.length > 0) {
                            setFiles(f => [...f, ...e.target.files]);
                        };
                    }} />
                </div>
            </p>
            <p>
                <StyledButton isDisabled={spinner} isSpinner={spinner} style={{width: "100%", gridColumn: "1 / span 2"}} onClick={processQueue}>Add to queue</StyledButton>
            </p>
        </div>

        <div className="route__dataImports__midasAds__line"></div>

        {queues.length > 0 && <StyledButton isDisabled={spinner} isSpinner={spinner} onClick={finalizeQueue} style={{marginBottom: "20px"}}>Finalize</StyledButton>}
        <FilteredCustomTable
            accent="#6C5DD3"
            theme={themeSelector}
            headers={["Date", "Items", ""]}
            customColumns={(new Array(3)).fill("max-content")}
            style={{columnGap: "40px"}}
            data={(()=>{
                if (queues.length === 0) return [[{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]];

                let out = [];
                for (let q of queues) {
                    out.push([
                        {keyID: q.Date, type: "text", text: moment(q.Date, "DD.MM.YYYY").toDate().toLocaleDateString()},
                        {keyID: q.Date, type: "text", text: q.Data.length},
                        {keyID: q.Date, type: "button", text: "Remove", onClick: () => setQueues(qq => qq.filter(qqq => qqq.Date !== q.Date))}
                    ]);
                };

                return out;
            })()}
        />
    </div>
};

export default DataImportsMidasAds;