import React from "react";
import "./index.scss";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import moment from "moment";
import useOnScreen from "../../../modules/hooks/useOnScreen";
import useDefer from "../../../modules/hooks/useDefer";
import { animateBox } from "../../../modules/componentAnimation";
import { getParamsFromURLObject } from "../../../modules/urlModule";

import * as backendModule from "../../../modules/backendModule";
import * as basicStylesModule from "../../../modules/basicStylesModule";
import * as siteFunctionsActions from "../../../actions/siteFunctionsActions";
import * as miscModule from "../../../modules/miscModule";

import { FilteredCustomTable } from "../../../components/customComponents/Table";
import StyledButton from "../../../components/styledComponents/Button";

import FilterByDate from "../../../components/filters/FilterByDate";
import FilterBySearch from "../../../components/filters/FilterBySearch";
import AdvancedDropdown from "../../../components/customComponents/AdvancedDropdown";
import Dropdown from "../../../components/customComponents/Dropdown";
import Spinner from "../../../components/customComponents/Spinner";
import YesNoModal from "../../../components/modals/YesNoModal";

const _positiveNextSteps = [
    "Scale up budget",
    "Replicate strategy",
    "Optimize further"
];
const _neutralNextSteps = [
    "Test alternative approach",
    "Monitor performance",
    "Pause and analyze",
    "Tweak creative"
];
const _negativeNextSteps = [
    "Revert changes",
    "Identify root cause",
    "Adjust budget",
    "Refine strategy",
    "Reevaluate campaign goals"
];

const UserCampaignPerformanceTracking = () => {
    const [spinner, setSpinner] = React.useState(false);
    const [data, setData] = React.useState();
    const [canPaginate, setCanPaginate] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState();
    const [selectedIntegration, setSelectedIntegration] = React.useState(null);
    const [search, setSearch] = React.useState("");
    const [allUsers, setAllUsers] = React.useState();
    const [selectedUser, setSelectedUser] = React.useState();
    const [campaignStatusFilter, setCampaignStatusFilter] = React.useState("all");

    const curDispatch = useDispatch();
    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const userInfoSelector = useSelector(state => state?.userData?.userData?.UserInfo ?? {});
    const campaignPerformanceTracking_reasonsSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.reasons ?? {});
    const campaignPerformanceTracking_outcomeSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.outcomes ?? {});
    const campaignPerformanceTracking_outcomeMappingsSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.outcomeMappings ?? {});
    const campaignPerformanceTracking_changeTypeSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.changeType ?? {});
    const supportedIntegrationsSelector = useSelector(state => state?.types?.supportedIntegrations ?? []);

    const timestampRef = React.useRef();
    const firstTimeRef = React.useRef(true);

    const curOnScreen = useOnScreen();
    const curDefer = useDefer();
    const searchDefer = useDefer();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;
        setCanPaginate(false);

        let filters = [];
        let campaignFilters = [];
        if (selectedDate) {
            filters.push({name: "createdAt", op: "pdgeq", value: selectedDate.start.toDate().getTime()});
            filters.push({name: "createdAt", op: "pdleq", value: selectedDate.end.toDate().getTime()});
        };

        if (selectedIntegration !== null && selectedIntegration !== undefined) {
            campaignFilters.push({name: "IntegrationType", op: "eq", value: selectedIntegration === -1 ? null : selectedIntegration});
        };

        if (selectedUser) {
            filters.push({name: "CreatedBy", op: "eq", value: selectedUser});
        };

        if (search) {
            let tmp = [];
            for (let item of search.split(" ").filter(s => s)) {
                tmp.push({or: [
                    {name: "ID", op: "like", value: item},
                    {name: "RollingID", op: "like", value: item},
                    {name: "CampaignName", op: "like", value: item}
                ]});
            };
            campaignFilters.push({and: tmp});
        };

        if (campaignStatusFilter) {
            switch (campaignStatusFilter) {
                case "not-started":
                    filters.push({name: "startedAt", op: "eq", value: null});
                    break;
                case "active":
                    filters.push({and: [
                        {name: "RemainingDuration", op: "gt", value: 0},
                        {name: "startedAt", op: "neq", value: null},
                        {name: "isPaused", op: "eq", value: false}
                    ]});
                    break;
                case "paused":
                    filters.push({name: "isPaused", op: "eq", value: true});
                    break;
                case "finished":
                    filters.push({name: "RemainingDuration", op: "leq", value: 0});
                    break;
                default: break;
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/performanceTracking/getAll`,
            data: {
                limit: 20,
                offset: 0,
                orders: [{name: "createdAt", order: "desc"}],
                filters,
                campaignFilters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);
            };
            setData(res.data);
            setTimeout(() => setSpinner(false), 200);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const continueData = ts => {
        if (timestampRef.current !== ts) return;
        if (!data) return;
        if (data?.status !== "ok") return;

        setCanPaginate(false);

        let filters = [];
        let campaignFilters = [];
        if (selectedDate) {
            filters.push({name: "createdAt", op: "pdgeq", value: selectedDate.start.toDate().getTime()});
            filters.push({name: "createdAt", op: "pdleq", value: selectedDate.end.toDate().getTime()});
        };

        if (selectedIntegration !== null && selectedIntegration !== undefined) {
            campaignFilters.push({name: "IntegrationType", op: "eq", value: selectedIntegration === -1 ? null : selectedIntegration});
        };

        if (selectedUser) {
            filters.push({name: "CreatedBy", op: "eq", value: selectedUser});
        };

        if (search) {
            let tmp = [];
            for (let item of search.split(" ").filter(s => s)) {
                tmp.push({or: [
                    {name: "ID", op: "like", value: item},
                    {name: "RollingID", op: "like", value: item},
                    {name: "CampaignName", op: "like", value: item}
                ]});
            };
            campaignFilters.push({and: tmp});
        };

        if (campaignStatusFilter) {
            switch (campaignStatusFilter) {
                case "not-started":
                    filters.push({name: "startedAt", op: "eq", value: null});
                    break;
                case "active":
                    filters.push({and: [
                        {name: "RemainingDuration", op: "gt", value: 0},
                        {name: "startedAt", op: "neq", value: null},
                        {name: "isPaused", op: "eq", value: false}
                    ]});
                    break;
                case "paused":
                    filters.push({name: "isPaused", op: "eq", value: true});
                    break;
                case "finished":
                    filters.push({name: "RemainingDuration", op: "leq", value: 0});
                    break;
                default: break;
            };
        };

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/performanceTracking/getAll`,
            data: {
                limit: 20,
                offset: 0,
                filters: [
                    {name: "ID", op: "notIn", value: data.data.map(d => d.ID)},
                    ...filters
                ],
                orders: [{name: "createdAt", order: "desc"}],
                campaignFilters
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length >= 20) setCanPaginate(true);

                setData(d => {
                    return {
                        ...d,
                        data: [
                            ...d.data,
                            ...res.data.data
                        ]
                    };
                });
            };
        }).catch(() => null);
    };

    const getAllUsers = async () => {
        let curUsers = await axios({
            method: "POST",
            url: `${backendModule.backendURL}/users/getAllUsers`,
            data: {
                filters: [
                    {name: "ID", op: "neq", value: userInfoSelector?.ID}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") return res.data.data;
            return [];
        }).catch(() => []);

        setAllUsers([
            {ID: userInfoSelector.ID, Username: userInfoSelector.Username},
            ...curUsers
        ]);
    };

    const getValue = (item, column) => {
        let sv = item?.StartingValues?.[column];
        let cv = item?.CurrentValues?.[column];
        let color = null;

        if (campaignPerformanceTracking_outcomeMappingsSelector[item.Outcome] === column) {
            color = item.CurrentPositive ? (themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight) : (themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight);
        };

        if (sv !== null && sv !== undefined) sv = Number(sv).toFixed(["Conversions", "Visits"].includes(column) ? 0 : 2);
        if (cv !== null && cv !== undefined) cv = Number(cv).toFixed(["Conversions", "Visits"].includes(column) ? 0 : 2);

        return <p>
            <span>{sv ?? "-"}</span>&nbsp;/&nbsp;
            <span style={{color: color}}>{cv ?? "-"}</span>
        </p>;
    };

    const renderText = text => {
        const wrapStyle = {textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"};
        return <p style={wrapStyle} title={text}>{text}</p>;
    };

    const getAchieved = item => {
        if (item.isPaused) return <span style={{color: "gray"}}>Paused</span>

        if (![true, false].includes(item.isAchieved)) {
            if (!item.startedAt) return <span style={{color: "gray"}}>Not yet started</span>
            return <span style={{color: "gray"}}>Finishes {moment(item.startedAt).add(1, "day").startOf("day").add(item.Duration, "hours").fromNow()}</span>
        };

        if (item.isAchieved) return <span style={{color: themeSelector === "dark" ? basicStylesModule.successColor : basicStylesModule.successColorLight}}>Positive</span>
        if (item.isNeutral) return <span style={{color: themeSelector === "dark" ? "white" : "black"}}>Neutral</span>
        return <span style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>Negative</span>;
    };

    const checkStartedDate = (item) => {
        if (item.startedAt) return <span style={{cursor: "pointer"}} onClick={() => animateBox(<UserCampaignPerformanceTracking_list
            data={item}
            onChange={() => {
                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);
            }}
            renderText={renderText}
            getAchieved={getAchieved}
            getValue={getValue}
        />)}>{moment(item.startedAt).toDate().toLocaleString()}</span>;

        const TmpButton = () => {
            const [spinner, setSpinner] = React.useState(false);

            const onButtonClick = () => animateBox(<YesNoModal
                heading="Are you sure?"
                text={[`This will start the campaign tracking process and can not be undone.`, <br />, <span style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>NOTE: the campaign tracking will start on {moment().add(1, "day").toDate().toLocaleDateString()}</span>]}
                buttonLeftText="No"
                buttonRightText="Yes"
                isRightButtonNormal={true}
                buttonRightCallback={args => {
                    args.disabledAll(true);
                    args.spinner(true);
                    setSpinner(true);
                    
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/campaigns/performanceTracking/activate`,
                        data: {
                            ID: item.ID
                        },
                        ...backendModule.axiosConfig
                    }).then(res => {
                        if (res.data.status === "ok") {
                            let ts = Date.now();
                            timestampRef.current = ts;
                            getData(ts);
                            args.close();
                        } else {
                            args.errorMessage("Error while activating the campaign!");
                        };
                    }).catch(() => {
                        args.errorMessage("Server timed out!");
                    }).finally(() => {
                        args.disabledAll(false);
                        args.spinner(false);
                        setSpinner(false);
                    });
                }}
            />);

            return <StyledButton style={{
                height: "100%",
                margin: "0 auto",
                display: "block",
                background: "transparent",
                border: "1px solid #5C7582B2",
                color: themeSelector === "dark" ? "white" : "black",
                padding: "0 10px"
            }} isSpinner={spinner} isDisabled={spinner} onClick={onButtonClick}>Activate</StyledButton>
        };

        return <div style={{
            display: "flex",
            alignItems: "center",
            gap: "10px"
        }}>
            <TmpButton />
            <StyledButton style={{
                height: "100%",
                margin: "0 auto",
                display: "block",
                background: "transparent",
                border: "1px solid #5C7582B2",
                color: themeSelector === "dark" ? "white" : "black",
                padding: "0 10px"
            }} onClick={() => animateBox(<UserCampaignPerformanceTracking_list
                data={item}
                onChange={() => {
                    let ts = Date.now();
                    timestampRef.current = ts;
                    getData(ts);
                }}
                renderText={renderText}
                getAchieved={getAchieved}
                getValue={getValue}
            />)}>Details</StyledButton>
        </div>;
    };

    const getIntegrationType = item => {
        if (!item?.Campaign) return "-";

        let type = item.Campaign.IntegrationType;
        if (type === null) type = -1;
        let foundIntegration = supportedIntegrationsSelector.find(si => si.Type === type);
        if (!foundIntegration) return "-";

        return <div className="route__userCpf__integration">
            <img src={miscModule.getTrackingProfileImage(foundIntegration.NamedType)} />
            <span>{foundIntegration.Name}</span>
        </div>
    };

    const getCampaignChanges = ID => {
        if (!data) return "-";
        if (data?.status !== "ok") return "-";

        return data.data.filter(d => d.CampaignID === ID).length;
    };

    React.useEffect(() => {
        if (!canPaginate) return;
        if (!curOnScreen.isIntersecting) return;

        setCanPaginate(false);
        try {
            curOnScreen.observer.unobserve(curOnScreen.measureRef);
        } catch {};

        let ts = Date.now();
        timestampRef.current = ts;
        setTimeout(() => continueData(ts), 500);
    }, [canPaginate, curOnScreen.isIntersecting]);

    React.useEffect(() => {
        const handler = () => {
            let ts = Date.now();
            timestampRef.current = ts;
            setSpinner(true);
            getData(ts);
        };

        if (firstTimeRef.current) {
            firstTimeRef.current = false;
            let urlParams = getParamsFromURLObject(String(window.location));
            if (urlParams?.["search"]) {
                setSearch(urlParams["search"]);
                return;
            };
        };
        
        curDispatch(siteFunctionsActions.addHeaderRefreshAction(handler));
        curDefer(handler, 500);

        return () => {
            try {
                curDispatch(siteFunctionsActions.removeHeaderRefreshAction(handler));
            } catch {};
        };
    }, [selectedDate, selectedIntegration, search, selectedUser, campaignStatusFilter]);

    React.useEffect(() => {
        getAllUsers();
    }, []);

    return <div className="route__userCpf">
        <div className="route__userCpf__filters">
            <FilterBySearch key={`campaign-perf-trk-${search}`} onChange={e => {
                let val = e;
                if (val === search) return;
                searchDefer(() => setSearch(val), 500);
            }} defaultValue={search} />
            <AdvancedDropdown
                headline="Select user"
                showSearch={true}
                data={allUsers ? [
                    {key: "null", name: "All users", value: null},
                    ...allUsers.map(u => {
                        return {key: u.ID, name: u.Username, value: u.ID}
                    })
                ] : null}
                selected={(()=>{
                    if (!selectedUser) return 0;

                    return allUsers.indexOf(allUsers.find(u => u.ID === selectedUser)) + 1;
                })()}
                onChange={e => setSelectedUser(e?.value)}
            />
            <AdvancedDropdown
                headline="Campaign status"
                data={[
                    {name: "All", value: "all"},
                    {name: "Not started", value: "not-started"},
                    {name: "Active", value: "active"},
                    {name: "Paused", value: "paused"},
                    {name: "Finished", value: "finished"}
                ]}
                onChange={e => setCampaignStatusFilter(e?.value)}
                selected={(()=>{
                    if (!campaignStatusFilter) return null;
                    switch (campaignStatusFilter) {
                        case "all": return 0;
                        case "not-started": return 1;
                        case "active": return 2;
                        case "paused": return 3;
                        case "finished": return 4;
                    };
                })()}
            />
            <AdvancedDropdown
                headline="Select integration"
                data={[
                    {key: "all", name: "All integrations", value: null},
                    ...supportedIntegrationsSelector.map(si => {
                        return {key: si.FullID, name: si.Name, value: si.Type, image: miscModule.getTrackingProfileImage(si.NamedType)};
                    })
                ]}
                onChange={e => setSelectedIntegration(e?.value)}
                selected={(()=>{
                    if (selectedIntegration === null || selectedIntegration === undefined) return 0;
                    return supportedIntegrationsSelector.indexOf(supportedIntegrationsSelector.find(s => s.Type === selectedIntegration)) + 1;
                })()}
            />
            <FilterByDate
                defaultValue="all"
                onChange={e => setSelectedDate(e)}
            />
            {(userInfoSelector?.Flags?.isAdmin || userInfoSelector?.Flags?.isTeamLeader || userInfoSelector?.Flags?.canAddCampaignPerformance) && <StyledButton
                style={{width: "100%", height: "100%", padding: 0}}
                onClick={() => animateBox(<UserCampaignPerformanceTracking_add
                    onChange={() => {
                        let ts = Date.now();
                        timestampRef.current = ts;
                        getData(ts);
                    }}
                />)}
            >+</StyledButton>}
        </div>

        <FilteredCustomTable
            theme={themeSelector}
            accent="#6C5DD3"
            showSpinner={data?.status === "ok" ? spinner : false}
            spinnerColor={themeSelector === "dark" ? "white" : "black"}
            headers={["ID", "Date started", "Campaign", "Source", "Changes", "Reason", "Expectations", "Outcome", "Change type", "Description", "Visits", "Revenue", "CTR", "CR", "AR", "CPA", "CPAO", "ADP", "Conversions"]}
            customColumns={["max-content", "max-content", "100px", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "100px", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content", "max-content"]}
            style={{columnGap: "40px", width: "100%", maxHeight: "100%", overflow: "auto", height: "max-content"}}
            data={(()=>{
                if (!data) return [[{keyID: "noData-spinner", type: "spinner", color: themeSelector === "dark" ? "white" : "black"}]];
                if (data?.status !== "ok") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data!", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                let out = [];
                let campaignIDs = [];
                for (let item of data.data) {
                    if (campaignIDs.includes(item.CampaignID)) continue;
                    campaignIDs.push(item.CampaignID);

                    out.push([
                        {keyID: item.ID, type: "text", text: item.ID},
                        {keyID: item.ID, type: "text", text: checkStartedDate(item)},
                        {keyID: item.ID, type: "text", text: renderText(item?.Campaign?.CampaignName)},
                        {keyID: item.ID, type: "text", text: getIntegrationType(item)},
                        {keyID: item.ID, type: "text", text: getCampaignChanges(item.CampaignID)},
                        {keyID: item.ID, type: "text", text: campaignPerformanceTracking_reasonsSelector[item.Reason], style: {color: basicStylesModule.errorColorGeneric}},
                        {keyID: item.ID, type: "text", text: campaignPerformanceTracking_outcomeSelector[item.Outcome], style: {color: basicStylesModule.errorColorGeneric}},
                        {keyID: item.ID, type: "text", text: getAchieved(item)},
                        {keyID: item.ID, type: "text", text: campaignPerformanceTracking_changeTypeSelector[item.ChangeType], style: {color: basicStylesModule.errorColorGeneric}},
                        {keyID: item.ID, type: "text", text: renderText(item.Description)},
                        {keyID: item.ID, type: "text", text: getValue(item, "Visits")},
                        {keyID: item.ID, type: "text", text: getValue(item, "Revenue")},
                        {keyID: item.ID, type: "text", text: getValue(item, "CTR_IN")},
                        {keyID: item.ID, type: "text", text: getValue(item, "CR")},
                        {keyID: item.ID, type: "text", text: getValue(item, "AR")},
                        {keyID: item.ID, type: "text", text: getValue(item, "CPA")},
                        {keyID: item.ID, type: "text", text: getValue(item, "CPAO")},
                        {keyID: item.ID, type: "text", text: getValue(item, "ADP")},
                        {keyID: item.ID, type: "text", text: getValue(item, "Conversions")},
                    ]);
                };

                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                return out;
            })()}
        />
        {canPaginate && <div style={{width: "100%", paddingBottom: "20px"}} ref={curOnScreen.measureRef}></div>}
    </div>;
};

const UserCampaignPerformanceTracking_add = props => {
    const [selectedCampaign, setSelectedCampaign] = React.useState();
    const [infoP, setInfoP] = React.useState({
        text: "",
        hadError: "",
        inputs: []
    })
    const [spinner, setSpinner] = React.useState(false);

    const [selectedReason, setSelectedReason] = React.useState();
    const [selectedOutcome, setSelectedOutcome] = React.useState();
    const [selectedChangeType, setSelectedChangeType] = React.useState();
    const [selectedNextStep, setSelectedNextStep] = React.useState();
    const durationRef = React.useRef();
    const descriptionRef = React.useRef();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const campaignPerformanceTracking_reasonsSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.reasons ?? {});
    const campaignPerformanceTracking_outcomeSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.outcomes ?? {});
    const campaignPerformanceTracking_changeTypeSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.changeType ?? {});

    const addPerformanceTracking = async () => {
        if (spinner) return;
        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        let data = {
            CampaignID: selectedCampaign.ID,
            Reason: selectedReason,
            Outcome: selectedOutcome,
            ChangeType: selectedChangeType,
            Description: descriptionRef.current.value,
            Duration: durationRef.current.value,

            utcOffset: (new Date()).getTimezoneOffset()
        };

        if (!data.Reason) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["reason"], text: "Reason must be selected"}});
        if (!data.Outcome) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["outcome"], text: "Expectation must be selected"}});
        if (!data.ChangeType) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["change-type"], text: "Change type must be selected"}});
        if (props.CampaignID && !selectedNextStep) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["next-step"], text: "Next step can't be empty"}});

        data.Duration = Number(data.Duration);
        if (isNaN(data.Duration) || data.Duration <= 0) return setInfoP(ip => {return {...ip, hadError: true, inputs: ["duration"], text: "Duration is invalid"}});
        data.Duration *= 24;

        setSpinner(true);
        if (props.CampaignID) {
            let nextStepFinished = await axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/performanceTracking/updateNextSteps`,
                data: {
                    ID: props.PerformanceID,
                    NextSteps: selectedNextStep
                },
                ...backendModule.axiosConfig
            }).then(res => res.data).catch(() => backendModule.genericError);
            if (nextStepFinished.status !== "ok") {
                setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Error while saving next steps!"}});
                setSpinner(false);
                return;
            };
        };
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/performanceTracking/create`,
            data,
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (typeof(props.onChange) === "function") props.onChange();
                props.onClose();
                return;
            } else {
                return setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Error while creating the report tracking!"}});
            };
        }).catch(() => {
            return setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Server timed out!"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    const checkCampaign = (ID) => {
        if (!ID) return;

        setInfoP(ip => {return {...ip, hadError: false, inputs: []}});

        setSpinner(true);
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/getAllCampaignsWithoutData`,
            data: {
                allUsers: true,
                filters: [
                    {or: [
                        {name: "ID", op: "eq", value: ID},
                        {name: "RollingID", op: "eq", value: ID}
                    ]}
                ],
                limit: 1
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (res.data.status === "ok") {
                if (res.data.data.length === 1) {
                    setSelectedCampaign(res.data.data[0]);
                } else {
                    setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Campaign not found!"}});
                };
            } else {
                setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Error while fetching campaigns"}});
            };
        }).catch(() => {
            setInfoP(ip => {return {...ip, hadError: true, inputs: [], text: "Server timed out"}});
        }).finally(() => {
            setSpinner(false);
        });
    };

    React.useEffect(() => {
        if (props.CampaignID) {
            checkCampaign(props.CampaignID);
        } else {
            let curParams = getParamsFromURLObject(String(window.location));
            if (curParams?.search) checkCampaign(curParams?.search);
        };
    }, []);
    
    return <div className="genericModal">
        <div className="genericModal__wrap">
            <div className="genericModal__wrap__head">
                <div className="genericModal__wrap__head__left">Add campaign tracking</div>
                <div className="genericModal__wrap__head__right" style={{backgroundImage: `url("/images/icon_close.svg")`}} onClick={props.onClose}></div>
            </div>

            {selectedCampaign ? <>
                <div className="genericModal__wrap__input">
                    <p>{selectedCampaign.CampaignName}</p>
                </div>

                {props.CampaignID && <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("next-step") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Next step</p>
                    <Dropdown
                        accent="#6C5DD3"
                        theme={themeSelector}
                        inlinePlaceholder="Next step for the campaign"
                        data={(props.isAchieved ? _positiveNextSteps : (props.isNeutral ? _neutralNextSteps : _negativeNextSteps)).map(t => {
                            return {name: t, value: t};
                        })}
                        onChange={e => e?.value && setSelectedNextStep(e?.value)}
                    />
                </div>}
                <div className={`genericModal__wrap__input ${infoP.inputs.includes("duration") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Duration (in days)</p>
                    <input ref={durationRef} type="text" placeholder="Duration" />
                </div>
                <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("reason") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Reason</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        inlinePlaceholder="Reason"
                        data={Object.keys(campaignPerformanceTracking_reasonsSelector).map(key => {
                            return {name: campaignPerformanceTracking_reasonsSelector[key], value: key}
                        })}
                        onChange={e => e?.value && setSelectedReason(e.value)}
                        selected={(()=>{
                            if (!selectedReason) return null;
                            Object.keys(campaignPerformanceTracking_reasonsSelector).indexOf(selectedReason);
                        })()}
                    />
                </div>
                <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("outcome") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Outcome</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        inlinePlaceholder="Expectation"
                        data={Object.keys(campaignPerformanceTracking_outcomeSelector).map(key => {
                            return {name: campaignPerformanceTracking_outcomeSelector[key], value: key}
                        })}
                        onChange={e => e?.value && setSelectedOutcome(e.value)}
                        selected={(()=>{
                            if (!selectedOutcome) return null;
                            Object.keys(campaignPerformanceTracking_outcomeSelector).indexOf(selectedOutcome);
                        })()}
                    />
                </div>
                <div className={`genericModal__wrap__input genericModal__wrap__input--dropdown ${infoP.inputs.includes("change-type") ? "genericModal__wrap__input--error" : ""}`}>
                    <p>Change type</p>
                    <Dropdown
                        theme={themeSelector}
                        accent="#6C5DD3"
                        inlinePlaceholder="Change type"
                        data={Object.keys(campaignPerformanceTracking_changeTypeSelector).map(key => {
                            return {name: campaignPerformanceTracking_changeTypeSelector[key], value: key}
                        })}
                        onChange={e => e?.value && setSelectedChangeType(e.value)}
                        selected={(()=>{
                            if (!selectedChangeType) return null;
                            Object.keys(campaignPerformanceTracking_changeTypeSelector).indexOf(selectedChangeType);
                        })()}
                    />
                </div>
                <div className="genericModal__wrap__input genericModal__wrap__input--text">
                    <p>Description</p>
                    <textarea ref={descriptionRef} placeholder="Description"></textarea>
                </div>
            </> : <>
                <div className="genericModal__wrap__input">
                    <p>Campaign ID</p>
                    <input type="text" placeholder="Campaign ID (eg: 10143 or 3354ae0e-3ae3-485d-9f5e-d534f2e4d12a)" />
                    <StyledButton isSpinner={spinner} isDisabled={spinner} onClick={e => {
                        let baseID = e.target.parentNode.querySelector("input").value;
                        checkCampaign(baseID);
                    }}>Check ID</StyledButton>
                </div>
            </>}

            {selectedCampaign && <div className="genericModal__wrap__buttons">
                <div className="genericModal__wrap__buttons__btn genericModal__wrap__buttons__btn--secondary" onClick={props.onClose}>Close</div>
                <div className="genericModal__wrap__buttons__btn" onClick={e => spinner ? e?.stopPropagation() : addPerformanceTracking()}>{spinner ? <Spinner style={{width: "17px", height: "17px"}} color={themeSelector === "dark" ? "white" : "black"} /> : "Add"}</div>
            </div>}

            {infoP.text && <p className="genericModal__wrap__infoP" style={{opacity: infoP.hadError ? 1 : 0}}>{infoP.text}</p>}
        </div>
    </div>
};

const UserCampaignPerformanceTracking_list = props => {
    const [data, setData] = React.useState();
    const [nextStepTaken, setNextStepTaken] = React.useState(false);
    const [hadUpdate, setHadUpdate] = React.useState(false);

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const campaignPerformanceTracking_reasonsSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.reasons ?? {});
    const campaignPerformanceTracking_outcomeSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.outcomes ?? {});
    const campaignPerformanceTracking_changeTypeSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.changeType ?? {});

    const wrapRef = React.useRef();
    const timestampRef = React.useRef();

    const getData = (ts) => {
        if (timestampRef.current !== ts) return;

        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/performanceTracking/getAll`,
            data: {
                limit: null,
                offset: 0,
                orders: [{name: "createdAt", order: "desc"}],
                campaignFilters: [
                    {name: "ID", op: "eq", value: props.data.CampaignID}
                ]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            if (timestampRef.current !== ts) return;
            setData(res.data);
        }).catch(() => {
            if (timestampRef.current !== ts) return;
            setData(backendModule.genericError);
        });
    };

    const PauseButton = () => {
        const [isPaused, setIsPaused] = React.useState(props.data.isPaused);
        const [spinner, setSpinner] = React.useState(false);

        const toggle = () => {
            setSpinner(true);
            setHadUpdate(true);
            axios({
                method: "POST",
                url: `${backendModule.backendURL}/campaigns/performanceTracking/toggle`,
                data: {
                    ID: props.data.ID
                },
                ...backendModule.axiosConfig
            }).then(res => {
                if (res.data.status === "ok") {
                    if (typeof(props.onChange) === "function") props.onChange();
                    setIsPaused(ip => !ip);
                };
            }).catch(() => null).finally(() => {
                setSpinner(false);
            });
        };

        return <StyledButton onClick={toggle} isDisabled={spinner} isSpinner={spinner} style={{marginLeft: "auto", marginRight: "20px", height: "100%"}}>{isPaused ? "Resume" : "Pause"}</StyledButton>
    };

    const checkStartedDate = (item) => {
        if (item.startedAt) return <span style={{cursor: "pointer"}} onClick={() => animateBox(<UserCampaignPerformanceTracking_details
            data={item}
            onChange={() => {
                let ts = Date.now();
                timestampRef.current = ts;
                getData(ts);
            }}
        />)}>{moment(item.startedAt).toDate().toLocaleString()}</span>;

        const TmpButton = () => {
            const [spinner, setSpinner] = React.useState(false);

            const onButtonClick = () => animateBox(<YesNoModal
                heading="Are you sure?"
                text={[`This will start the campaign tracking process and can not be undone.`, <br />, <span style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>NOTE: the campaign tracking will start on {moment().add(1, "day").toDate().toLocaleDateString()}</span>]}
                buttonLeftText="No"
                buttonRightText="Yes"
                isRightButtonNormal={true}
                buttonRightCallback={args => {
                    args.disabledAll(true);
                    args.spinner(true);
                    setSpinner(true);
                    
                    setHadUpdate(true);
                    axios({
                        method: "POST",
                        url: `${backendModule.backendURL}/campaigns/performanceTracking/activate`,
                        data: {
                            ID: item.ID
                        },
                        ...backendModule.axiosConfig
                    }).then(res => {
                        if (res.data.status === "ok") {
                            let ts = Date.now();
                            timestampRef.current = ts;
                            getData(ts);
                            args.close();
                        } else {
                            args.errorMessage("Error while activating the campaign!");
                        };
                    }).catch(() => {
                        args.errorMessage("Server timed out!");
                    }).finally(() => {
                        args.disabledAll(false);
                        args.spinner(false);
                        setSpinner(false);
                    });
                }}
            />);

            return <StyledButton style={{
                height: "100%",
                margin: "0 auto",
                display: "block",
                background: "transparent",
                border: "1px solid #5C7582B2",
                color: themeSelector === "dark" ? "white" : "black"
            }} isSpinner={spinner} isDisabled={spinner} onClick={onButtonClick}>Activate</StyledButton>
        };

        return <TmpButton />;
    };

    const onClose = () => {
        if (props.onChange && hadUpdate) props.onChange();

        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    React.useEffect(() => {
        if (nextStepTaken) setHadUpdate(true);
        let ts = Date.now();
        timestampRef.current = ts;
        getData(ts);
    }, [nextStepTaken]);

    return <div className="route__userCpf__details" onClick={() => onClose()}>
        <div className="route__userCpf__details__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>

            <div className="route__userCpf__details__wrap__top">
                <div className="route__userCpf__details__wrap__top__left">{props.data?.Campaign?.CampaignName}</div>

                {(props.data.startedAt && props.data.RemainingDuration) ? <>
                    <PauseButton />
                </> : null}

                <div className="route__userCpf__details__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__userCpf__details__wrap__content">
                {data ? <>
                    {data.status === "ok" ? <>
                        {[true, false].includes(props.data.isAchieved) ? <StyledButton style={{
                            marginBottom: "20px",
                            position: "sticky",
                            left: 0
                        }} onClick={() => animateBox(<UserCampaignPerformanceTracking_add
                            PerformanceID={props.data.ID}
                            CampaignID={props.data.CampaignID}
                            isAchieved={props.data.isAchieved}
                            isNeutral={props.data.isNeutral}
                            onChange={e => setNextStepTaken(true)}
                        />)}>Take the next step</StyledButton> : null}
                        <FilteredCustomTable
                            theme={themeSelector}
                            accent="#6C5DD3"
                            headers={["ID", "Date started", "Reason", "Expectations", "Outcome", "Change type", "Description", "Next steps", "Visits", "Revenue", "CTR", "CR", "AR", "CPA", "CPAO", "ADP", "Conversions"]}
                            customColumns={[
                                ...(new Array(6).fill("max-content")),
                                "100px",
                                ...(new Array(10).fill("max-content"))
                            ]}
                            style={{columnGap: "40px"}}
                            data={(()=>{
                                if (data?.status !== "ok") return [[{keyID: "noData-error", type: "custom", data: "There was an error while fetching data...", style: {color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}]];

                                let out = [];
                                for (let item of data.data) {
                                    out.push([
                                        {keyID: String(item.ID), type: "text", text: item.ID},
                                        {keyID: String(item.ID), type: "text", text: checkStartedDate(item)},
                                        {keyID: String(item.ID), type: "text", text: campaignPerformanceTracking_reasonsSelector[item.Reason], style: {color: basicStylesModule.errorColorGeneric}},
                                        {keyID: String(item.ID), type: "text", text: campaignPerformanceTracking_outcomeSelector[item.Outcome], style: {color: basicStylesModule.errorColorGeneric}},
                                        {keyID: String(item.ID), type: "text", text: props.getAchieved(item)},
                                        {keyID: String(item.ID), type: "text", text: campaignPerformanceTracking_changeTypeSelector[item.ChangeType], style: {color: basicStylesModule.errorColorGeneric}},
                                        {keyID: String(item.ID), type: "text", text: props.renderText(item.Description)},
                                        {keyID: String(item.ID), type: "text", text: props.renderText(item.NextSteps)},

                                        {keyID: String(item.ID), type: "text", text: props.getValue(item, "Visits")},
                                        {keyID: String(item.ID), type: "text", text: props.getValue(item, "Revenue")},
                                        {keyID: String(item.ID), type: "text", text: props.getValue(item, "CTR_IN")},
                                        {keyID: String(item.ID), type: "text", text: props.getValue(item, "CR")},
                                        {keyID: String(item.ID), type: "text", text: props.getValue(item, "AR")},
                                        {keyID: String(item.ID), type: "text", text: props.getValue(item, "CPA")},
                                        {keyID: String(item.ID), type: "text", text: props.getValue(item, "CPAO")},
                                        {keyID: String(item.ID), type: "text", text: props.getValue(item, "ADP")},
                                        {keyID: String(item.ID), type: "text", text: props.getValue(item, "Conversions")}
                                    ])
                                };
                                return out;
                            })()}
                        />
                    </> : <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>There was an error while fetching updates</p>}
                </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}

            </div>

        </div>
    </div>
};

const UserCampaignPerformanceTracking_details = props => {
    const [data, setData] = React.useState();

    const themeSelector = useSelector(state => state?.siteFunctions?.theme ?? "dark");
    const campaignPerformanceTracking_reasonsSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.reasons ?? {});
    const campaignPerformanceTracking_outcomeSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.outcomes ?? {});
    const campaignPerformanceTracking_changeTypeSelector = useSelector(state => state?.types?.campaignPerformanceTrackings?.changeType ?? {});

    const wrapRef = React.useRef();

    const onClose = () => {
        if (props.onChange) props.onChange();

        if (wrapRef.current) {
            wrapRef.current.animate([
                { right: getComputedStyle(wrapRef.current).right },
                { right: "-100%" }
            ], {
                duration: 300,
                iterations: 1,
                fill: "both",
                easing: "ease"
            });
        };

        props.onClose();
    };

    const durationToTime = duration => {
        let days = 0;
        let hours = Number(duration);

        while (hours >= 24) {
            days += 1;
            hours -= 24;
        };

        return `${days ? `${days} days ` : ""}${hours} hours`;
    };

    const checkHasValue = val => {
        if (val === null || val === undefined) return false;
        return true;
    };

    React.useEffect(() => {
        axios({
            method: "POST",
            url: `${backendModule.backendURL}/campaigns/performanceTracking/getUpdates`,
            data: {
                PerformanceTrackingID: props.data.ID,
                limit: null,
                offset: 0,
                orders: [{name: "createdAt", order: "desc"}]
            },
            ...backendModule.axiosConfig
        }).then(res => {
            setData(res.data);
        }).catch(() => setData(backendModule.genericError));
    }, []);

    React.useEffect(() => {
        if (!wrapRef.current) return;

        wrapRef.current.animate([
            { right: getComputedStyle(wrapRef.current).right },
            { right: 0 }
        ], {
            duration: 300,
            iterations: 1,
            fill: "both",
            easing: "ease"
        });
    }, [wrapRef.current]);

    return <div className="route__userCpf__details" onClick={() => onClose()}>
        <div className="route__userCpf__details__wrap" ref={wrapRef} onClick={e => e?.stopPropagation()}>

            <div className="route__userCpf__details__wrap__top">
                <div className="route__userCpf__details__wrap__top__left">{props.data?.Campaign?.CampaignName}</div>

                <div className="route__userCpf__details__wrap__top__right"><img src="/images/icon_close.svg" onClick={() => onClose()} /></div>
            </div>

            <div className="route__userCpf__details__wrap__content">
                <div className="route__userCpf__details__wrap__content__kpi">
                    <div className="route__userCpf__details__wrap__content__kpi__item">
                        <div className="route__userCpf__details__wrap__content__kpi__item__top">ID</div>
                        <div className="route__userCpf__details__wrap__content__kpi__item__bottom">{props.data.ID}</div>
                    </div>
                    <div className="route__userCpf__details__wrap__content__kpi__item">
                        <div className="route__userCpf__details__wrap__content__kpi__item__top">Created at</div>
                        <div className="route__userCpf__details__wrap__content__kpi__item__bottom">{moment(props.data.createdAt).toDate().toLocaleString()}</div>
                    </div>
                    <div className="route__userCpf__details__wrap__content__kpi__item">
                        <div className="route__userCpf__details__wrap__content__kpi__item__top">Started at</div>
                        <div className="route__userCpf__details__wrap__content__kpi__item__bottom">{props.data.startedAt ? moment(props.data.startedAt).toDate().toLocaleString() : "Not yet started"}</div>
                    </div>
                    <div className="route__userCpf__details__wrap__content__kpi__item">
                        <div className="route__userCpf__details__wrap__content__kpi__item__top">Runs for</div>
                        <div className="route__userCpf__details__wrap__content__kpi__item__bottom">{durationToTime(props.data.Duration)}</div>
                    </div>

                    <div className="route__userCpf__details__wrap__content__kpi__item route__userCpf__details__wrap__content__kpi__item--noBorder">
                        <div className="route__userCpf__details__wrap__content__kpi__item__top">Time remaining</div>
                        <div className="route__userCpf__details__wrap__content__kpi__item__bottom">{props.data.RemainingDuration ? durationToTime(props.data.RemainingDuration) : "Finished"}</div>
                    </div>
                    <div className="route__userCpf__details__wrap__content__kpi__item route__userCpf__details__wrap__content__kpi__item--noBorder">
                        <div className="route__userCpf__details__wrap__content__kpi__item__top">Reason</div>
                        <div className="route__userCpf__details__wrap__content__kpi__item__bottom">{campaignPerformanceTracking_reasonsSelector[props.data.Reason]}</div>
                    </div>
                    <div className="route__userCpf__details__wrap__content__kpi__item route__userCpf__details__wrap__content__kpi__item--noBorder">
                        <div className="route__userCpf__details__wrap__content__kpi__item__top">Expectation</div>
                        <div className="route__userCpf__details__wrap__content__kpi__item__bottom">{campaignPerformanceTracking_outcomeSelector[props.data.Outcome]}</div>
                    </div>
                    <div className="route__userCpf__details__wrap__content__kpi__item route__userCpf__details__wrap__content__kpi__item--noBorder">
                        <div className="route__userCpf__details__wrap__content__kpi__item__top">Change type</div>
                        <div className="route__userCpf__details__wrap__content__kpi__item__bottom">{campaignPerformanceTracking_changeTypeSelector[props.data.ChangeType]}</div>
                    </div>
                </div>

                {props.data.Description && <div className="route__userCpf__details__wrap__content__description"><span>Description: </span>{props.data.Description}</div>}

                {data ? <>
                    {data.status === "ok" ? <>
                        <FilteredCustomTable
                            theme={themeSelector}
                            accent="#6C5DD3"
                            headers={["Date", "Visits", "Revenue", "CTR", "CR", "AR", "CPA", "CPAO", "ADP", "Conversions"]}
                            customColumns={(new Array(10)).fill("max-content")}
                            style={{columnGap: "40px"}}
                            data={(()=>{
                                let out = [];

                                for (let item of data.data) {
                                    out.push([
                                        {keyID: item.ID, type: "text", text: moment(item.createdAt).toDate().toLocaleString()},
                                        {keyID: item.ID, type: "text", text: checkHasValue(item.Values["Visits"]) ? Number(item.Values["Visits"]).toFixed(0) : "-"},
                                        {keyID: item.ID, type: "text", text: checkHasValue(item.Values["Revenue"]) ? Number(item.Values["Revenue"]).toFixed(2) : "-"},
                                        {keyID: item.ID, type: "text", text: checkHasValue(item.Values["CTR_IN"]) ? Number(item.Values["CTR_IN"]).toFixed(2) : "-"},
                                        {keyID: item.ID, type: "text", text: checkHasValue(item.Values["CR"]) ? Number(item.Values["CR"]).toFixed(2) : "-"},
                                        {keyID: item.ID, type: "text", text: checkHasValue(item.Values["AR"]) ? Number(item.Values["AR"]).toFixed(2) : "-"},
                                        {keyID: item.ID, type: "text", text: checkHasValue(item.Values["CPA"]) ? Number(item.Values["CPA"]).toFixed(2) : "-"},
                                        {keyID: item.ID, type: "text", text: checkHasValue(item.Values["CPAO"]) ? Number(item.Values["CPAO"]).toFixed(2) : "-"},
                                        {keyID: item.ID, type: "text", text: checkHasValue(item.Values["ADP"]) ? Number(item.Values["ADP"]).toFixed(2) : "-"},
                                        {keyID: item.ID, type: "text", text: checkHasValue(item.Values["Conversions"]) ? Number(item.Values["ADP"]).toFixed(0) : "-"}
                                    ]);
                                };

                                if (out.length === 0) out.push([{keyID: "noData-noData", type: "custom", data: "Nothing to show..."}]);
                                return out;
                            })()}
                        />
                    </> : <p style={{color: themeSelector === "dark" ? basicStylesModule.errorColor : basicStylesModule.errorColorLight}}>There was an error while fetching updates</p>}
                </> : <Spinner style={{width: "32px", height: "32px"}} color={themeSelector === "dark" ? "white" : "black"} />}

            </div>

        </div>
    </div>
};

export default UserCampaignPerformanceTracking;